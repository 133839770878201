import { Pipe, PipeTransform } from '@angular/core';
import { ValidationResults } from '@ag-common-lib/lib';
import { checkAddressValidationStatus } from 'ag-common-svc/lib/utils/address.util';

@Pipe({ name: 'addressValidationStatus' })
export class AddressValidationStatusPipe implements PipeTransform {
  constructor() {}

  transform(validationResults: ValidationResults): any {
    const addressValidationStatus = checkAddressValidationStatus(validationResults);
    return addressValidationStatus;
  }
}
