<p
  class="editor__data-grid--empty"
  *ngIf="AddressGridType.shortExtended === type && !addresses?.length; else dataGridTmp"
>
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    class="addresses-grid__grid"
    [class.ag-profile-grid__grid]="AddressGridType.default !== type"
    [dataSource]="addresses"
    [showRowLines]="AddressGridType.default === type"
    [showBorders]="AddressGridType.default === type"
    [showColumnLines]="AddressGridType.default === type"
    [showColumnHeaders]="AddressGridType.short !== type"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="AddressGridType.default === type"
    [noDataText]="emptyMessage"
    (onRowRemoving)="handleRowRemoving($event)"
    height="100%"
  >
    <dxo-toolbar [visible]="AddressGridType.shortExtended !== type && hasPermissionsToAdd">
      <dxi-item location="before" [visible]="!!gridTitle">
        <div *dxTemplate>
          <b>
            {{ gridTitle }}
          </b>
        </div>
      </dxi-item>

      <dxi-item
        *ngIf="!inEditMode"
        location="after"
        widget="dxButton"
        locateInMenu="auto"
        [cssClass]="'ag-profile-grid__toolbar-button'"
        [options]="{
          hint: 'ENABLE EDIT',
          icon: 'edit',
          stylingMode: 'text',
          onClick: enableEditMode,
        }"
      ></dxi-item>

      <dxi-item
        *ngIf="inEditMode"
        location="after"
        widget="dxButton"
        locateInMenu="auto"
        [cssClass]="'ag-profile-grid__toolbar-button'"
        [options]="{
          hint: 'DISABLE EDIT',
          icon: 'undo',
          type: 'danger',
          stylingMode: 'text',
          onClick: disableEditMode,
        }"
      ></dxi-item>

      <dxi-item
        *ngIf="inEditMode"
        location="after"
        widget="dxButton"
        [cssClass]="type === AddressGridType.short ? 'ag-profile-grid__toolbar-button' : ''"
        [options]="{
          icon: 'plus',
          onClick: handleAddAddress,
          stylingMode: type === AddressGridType.short ? 'text' : 'outlined',
        }"
      ></dxi-item>
    </dxo-toolbar>

    <dxo-editing mode="popup" [useIcons]="true" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    </dxo-editing>

    <dxi-column
      [name]="'fullAddress'"
      [calculateDisplayValue]="fullAddressPipe.transform"
      [caption]="AddressGridType.default === type ? 'Address' : ''"
      cellTemplate="fullAddressCellTemplate"
    >
      <div *dxTemplate="let cell of 'fullAddressCellTemplate'" class="ag-profile-grid__text-with-icons">
        <span>
          {{ cell.displayValue }}
        </span>

        <i
          *ngIf="showShippingAddress && cell?.data?.is_primary_shipping"
          class="dx-icon-agi-shipping-address addresses-grid__list-icon"
        ></i>
        <i
          *ngIf="showBillingAddress && cell?.data?.is_primary_billing"
          class="dx-icon-agi-billing-address addresses-grid__list-icon"
        ></i>

        <ng-container [ngSwitch]="cell.data?.[AddressModelKeys.validationResponse]?.result | addressValidationStatus">
          <i
            *ngSwitchCase="'valid'"
            class="dx-icon-check addresses-grid__validation-status addresses-grid__validation-status--success"
            title="Valid"
          ></i>

          <i
            *ngSwitchCase="'warning'"
            class="dx-icon-warning addresses-grid__validation-status addresses-grid__validation-status--warning"
            [title]="
              cell.data?.[AddressModelKeys.validationResponse]?.result?.verdict?.addressComplete
                ? 'Validated, Not Completed'
                : 'Validated, Has Unconfirmed Data'
            "
          ></i>

          <i
            *ngSwitchCase="'invalid'"
            class="dx-icon dx-icon-agi-ban addresses-grid__validation-status addresses-grid__validation-status--failed"
            title="Not Validated"
          ></i>
        </ng-container>
      </div>
    </dxi-column>

    <dxi-column [dataField]="AddressModelKeys.address1" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.address2" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.city" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.state" [visible]="false" editCellTemplate="stateEditorTmp">
      <dxo-lookup [dataSource]="statesLookup$ | async" [displayExpr]="'description'" [valueExpr]="'value'"></dxo-lookup>
    </dxi-column>
    <dxi-column [dataField]="AddressModelKeys.zip" [visible]="false"></dxi-column>
    <dxi-column [dataField]="AddressModelKeys.country" [visible]="false">
      <dxo-lookup [dataSource]="countries" [displayExpr]="'name'" [valueExpr]="'code'"></dxo-lookup>
    </dxi-column>
    <dxi-column [dataField]="AddressModelKeys.county" [visible]="false"></dxi-column>

    <dxi-column
      [dataField]="AddressModelKeys.addressType"
      [caption]="AddressGridType.default === type ? 'Type' : ''"
      [visible]="showAddressType"
    >
      <dxo-lookup
        [dataSource]="typesLookup"
        [valueExpr]="BaseModelKeys.dbId"
        [displayExpr]="'description'"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column
      [visible]="showPhysicalLocation && AddressGridType.short !== type"
      [dataField]="AddressModelKeys.isPhysicalLocation"
      caption="Physical Location"
      width="160"
      cellTemplate="checkboxCellTemplate"
    ></dxi-column>

    <dxi-column
      [visible]="showShippingAddress && AddressGridType.short !== type"
      [dataField]="AddressModelKeys.isPrimaryShipping"
      caption="Shipping"
      width="80"
      cellTemplate="checkboxCellTemplate"
    ></dxi-column>

    <dxi-column
      [visible]="showBillingAddress && AddressGridType.short !== type"
      [dataField]="AddressModelKeys.isPrimaryBilling"
      caption="Billing"
      width="80"
      cellTemplate="checkboxCellTemplate"
    ></dxi-column>

    <div *dxTemplate="let cell of 'checkboxCellTemplate'">
      <i *ngIf="!!cell.value" class="dx-icon-check"></i>
    </div>

    <dxi-column
      type="buttons"
      [visible]="inEditMode && AddressGridType.shortExtended !== type"
      [width]="AddressGridType.short === type ? '60px' : undefined"
    >
      <dxi-button
        name="customEdit"
        icon="edit"
        [visible]="hasPermissionsToEdit"
        [onClick]="handleEditAddress"
      ></dxi-button>

      <dxi-button
        name="customDelete"
        icon="trash"
        [visible]="hasPermissionsToDelete"
        [onClick]="handleDeleteAddress"
      ></dxi-button>
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'stateEditorTmp'">
      <ag-shr-state-select-box [value]="cell.value" (valueChange)="cell.setValue($event)"> </ag-shr-state-select-box>
    </div>
  </dx-data-grid>
</ng-template>

<ag-shr-addresses-modal
  #addressesModalRef
  [showPhysicalLocation]="showPhysicalLocation"
  [showBillingAddress]="showBillingAddress"
  [showShippingAddress]="showShippingAddress"
  [addressToEdit]="addressToEdit"
  [addressFormTitle]="addressFormTitle"
  [checkIsAddressUniq]="checkIsAddressUniq"
  (onAddressSave)="handleAddressSave()"
  [typesLookup]="typesLookup"
></ag-shr-addresses-modal>
