import { NgModule } from '@angular/core';
import { MultipleClickDirective } from '../directives/multiple-clicks.directive';
import { BindArgumentsPipe } from './bind-arguments.pipe';
import { FormChangeDetectionPipe } from './form-change-detection.pipe';
import { FormateLogDatePipe } from './format-log-date.pipe';
import { FullAddressPipe } from './full-address.pipe';
import { FullNamePipe } from './full-name.pipe';
import { PathPipe } from './path.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { PhoneNumberMaskPipe } from './phone-number-mask.pipe';
import { PrimaryPhoneNumberPipe } from './primary-phone-number.pipe';
import { SafeHTMLPipe } from './safe-html.pipe';
import { TaskWorkflowDetailsPipe } from './task-workflow-details.pipe';
import { TimestampPipe } from './timestamp.pipe';
import { PrefixPipe } from './prefix.pipe';
import { DietaryConsiderationTypePipe } from './dietary-consideration-type.pipe';
import { GenderPipe } from './gender.pipe';
import { AgentCarrierPipe } from './agent-carrier.pipe';
import { SuffixPipe } from './suffix.pipe';
import { TShortSizePipe } from './t-short-size.pipe';
import { PrimaryShippingAddressPipe } from './primary-shipping-address.pipe';
import { PrimaryBillingAddressPipe } from './primary-billing-address.pipe';
import { AgencyPipe } from './agency.pipe';
import { LookupDescriptionPipe } from './lookup-description.pipe';
import { OtherSizePipe } from './other-sizes.pipe';
import { AgentNameByDbIdePipe } from './agent.pipe';
import { AgentPrimaryEmailAddressPipe } from './agent-primary-email-address.pipe';
import { VarDirective } from '../directives/var.directive';
import { EmptyPipe } from './empty.pipe';
import { MgaNamePipe } from './mga-name.pipe';
import { AgencyStatusThumbsIconPipe } from './agency-status-thumbs-up-icon.pipe';
import { AgencyStatusBanIconPipe } from './agency-status-ban-icon.pipe';
import { AgentStatusBanIconPipe } from './agent-status-ban-icon.pipe';
import { AgentStatusThumbsUpIconPipe } from './agent-status-thumbs-up-icon.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { WasabiImgPipe } from './wasabi-img.pipe';
import { WasabiArchivedImagesPipe } from './wasabi-archived-images.pipe';
import { LookupDescriptionTextPipe } from './lookup-description-text.pipe';
import { FormScreenByWidthPipe } from './form-screen-by-width.pipe';
import { DatesRangePipe } from './dates-range.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { AddToCalendarDirective } from '../directives/add-event-to-calendar.directive';
import { UsdCurrencyPipe } from './usd-currency.pipe';
import { AgencyPrimaryEmailAddressPipe } from './agency-primary-email-address.pipe';
import { WasabiFile } from './wasabi-file.pipe';
import { ExcursionConfigurationDisplayValuePipe } from './excursion-configuration-display-value.pipe';
import { TemplateWithArgumentsPipe } from './template-with-arguments.pipe';
import { ExcursionConfigurationDisplayNamePipe } from './excursion-configuration-display-name.pipe';
import { ConferenceTemplateWithArgumentsPipe } from './conference-template-with-arguments.pipe';
import { ConferenceGuestsSectionVisiblePipe } from './conference-registration/conference-guests-section-visible.pipe';
import { IsRequestedDifferentHotelCheckInCheckOutDatesPipe } from './is-requested-different-hotel-check-in-check-out-dates.pipe';
import { AgentMainInfoPrimaryEmailPipe } from './agent-main-info-primary-email.pipe';
import { AgentMainInfoPrimaryPhonePipe } from './agent-main-info-primary-phone.pipe';
import { AgentMainInfoAgencyPipe } from './agent-main-info-agency.pipe';
import { AgentMainInfoSocialsPipe } from './agent-main-info-socials.pipe';
import { AgentMainInfoThumbsUpIconPipe } from './agent-main-info-thumbs-up-icon.pipe';
import { HasPermissionPipe } from './has-permission.pipe';
import { HasAnyPermissionPipe } from './has-any-permission.pipe';
import { MilitaryTimeFormatPipe } from './military-time-format.pipe';
import { OptInGroupFilterBuilderFieldsPipe } from './opt-in-group-filter-builder-fields.pipe';
import { ConferenceNameByDbIdPipe } from './conference-name-by-db-id.pipe';
import { CaptionPipe } from './caption.pipe';
import { UserRolesNameByIdsPipe } from './user-roles-name-by-ids.pipe';
import { ArrayToStringPipe } from './array-to-string.pipe';
import { FilterBuilderGroupDisplayValuePipe } from './filter-builder-group-display-value.pipe';
import { NameTitleByMaskPipe } from './name-title-by-mask.pipe';
import { ChangeLogsDiffPipe } from './change-logs/change-logs-diff.pipe';
import { ChangeLogFieldsNamePipe } from './change-logs/change-log-fields-name.pipe';
import { HighlightFilterSearchTermPipe } from './highlight-filter-search-term.pipe';
import { MgaTeamLeadDescriptionPipe } from './mga-team-lead-desription.pipe';
import { AgentMainInfoPrimaryAddressesPipe } from './agent-main-info-primary-addresses.pipe';
import { AddressValidationStatusPipe } from './address-validation-status.pipe';

@NgModule({
  declarations: [
    SafeHTMLPipe,
    PathPipe,
    TimestampPipe,
    BindArgumentsPipe,
    FormateLogDatePipe,
    MultipleClickDirective,
    FullNamePipe,
    FormChangeDetectionPipe,
    TaskWorkflowDetailsPipe,
    PrimaryPhoneNumberPipe,
    PhoneNumberMaskPipe,
    FullAddressPipe,
    YesNoPipe,
    PrefixPipe,
    DietaryConsiderationTypePipe,
    GenderPipe,
    SuffixPipe,
    TShortSizePipe,
    PrimaryShippingAddressPipe,
    PrimaryBillingAddressPipe,
    AgencyPipe,
    LookupDescriptionPipe,
    OtherSizePipe,
    AgentNameByDbIdePipe,
    AgentPrimaryEmailAddressPipe,
    AgencyPrimaryEmailAddressPipe,
    VarDirective,
    EmptyPipe,
    MgaNamePipe,
    AgencyStatusThumbsIconPipe,
    AgencyStatusBanIconPipe,
    AgentStatusBanIconPipe,
    AgentStatusThumbsUpIconPipe,
    ShortNamePipe,
    WasabiImgPipe,
    WasabiArchivedImagesPipe,
    LookupDescriptionTextPipe,
    FormScreenByWidthPipe,
    DatesRangePipe,
    CustomCurrencyPipe,
    AddToCalendarDirective,
    UsdCurrencyPipe,
    WasabiFile,
    IsRequestedDifferentHotelCheckInCheckOutDatesPipe,
    ExcursionConfigurationDisplayValuePipe,
    ExcursionConfigurationDisplayNamePipe,
    TemplateWithArgumentsPipe,
    ConferenceTemplateWithArgumentsPipe,
    ConferenceGuestsSectionVisiblePipe,
    AgentMainInfoPrimaryEmailPipe,
    AgentMainInfoPrimaryPhonePipe,
    AgentMainInfoAgencyPipe,
    AgentMainInfoSocialsPipe,
    AgentMainInfoThumbsUpIconPipe,
    HasPermissionPipe,
    HasAnyPermissionPipe,
    MilitaryTimeFormatPipe,
    OptInGroupFilterBuilderFieldsPipe,
    ConferenceNameByDbIdPipe,
    CaptionPipe,
    UserRolesNameByIdsPipe,
    AgentCarrierPipe,
    ArrayToStringPipe,
    FilterBuilderGroupDisplayValuePipe,
    NameTitleByMaskPipe,
    ChangeLogsDiffPipe,
    ChangeLogFieldsNamePipe,
    HighlightFilterSearchTermPipe,
    MgaTeamLeadDescriptionPipe,
    AgentMainInfoPrimaryAddressesPipe,
    AddressValidationStatusPipe,
  ],
  exports: [
    SafeHTMLPipe,
    PathPipe,
    TimestampPipe,
    BindArgumentsPipe,
    FormateLogDatePipe,
    MultipleClickDirective,
    FullNamePipe,
    FormChangeDetectionPipe,
    TaskWorkflowDetailsPipe,
    PrimaryPhoneNumberPipe,
    PhoneNumberMaskPipe,
    FullAddressPipe,
    YesNoPipe,
    PrefixPipe,
    DietaryConsiderationTypePipe,
    GenderPipe,
    SuffixPipe,
    TShortSizePipe,
    PrimaryShippingAddressPipe,
    PrimaryBillingAddressPipe,
    AgencyPipe,
    LookupDescriptionPipe,
    OtherSizePipe,
    AgentNameByDbIdePipe,
    AgentPrimaryEmailAddressPipe,
    AgencyPrimaryEmailAddressPipe,
    VarDirective,
    EmptyPipe,
    MgaNamePipe,
    AgencyStatusThumbsIconPipe,
    AgencyStatusBanIconPipe,
    AgentStatusBanIconPipe,
    AgentStatusThumbsUpIconPipe,
    ShortNamePipe,
    WasabiImgPipe,
    WasabiArchivedImagesPipe,
    LookupDescriptionTextPipe,
    FormScreenByWidthPipe,
    DatesRangePipe,
    CustomCurrencyPipe,
    AddToCalendarDirective,
    UsdCurrencyPipe,
    WasabiFile,
    IsRequestedDifferentHotelCheckInCheckOutDatesPipe,
    ExcursionConfigurationDisplayValuePipe,
    ExcursionConfigurationDisplayNamePipe,
    TemplateWithArgumentsPipe,
    ConferenceTemplateWithArgumentsPipe,
    ConferenceGuestsSectionVisiblePipe,
    AgentMainInfoPrimaryEmailPipe,
    AgentMainInfoPrimaryPhonePipe,
    AgentMainInfoAgencyPipe,
    AgentMainInfoSocialsPipe,
    AgentMainInfoThumbsUpIconPipe,
    HasPermissionPipe,
    HasAnyPermissionPipe,
    MilitaryTimeFormatPipe,
    OptInGroupFilterBuilderFieldsPipe,
    ConferenceNameByDbIdPipe,
    CaptionPipe,
    UserRolesNameByIdsPipe,
    AgentCarrierPipe,
    ArrayToStringPipe,
    FilterBuilderGroupDisplayValuePipe,
    NameTitleByMaskPipe,
    ChangeLogsDiffPipe,
    ChangeLogFieldsNamePipe,
    HighlightFilterSearchTermPipe,
    MgaTeamLeadDescriptionPipe,
    AgentMainInfoPrimaryAddressesPipe,
    AddressValidationStatusPipe,
  ],
  providers: [
    SuffixPipe,
    WasabiImgPipe,
    IsRequestedDifferentHotelCheckInCheckOutDatesPipe,
    ExcursionConfigurationDisplayValuePipe,
    ExcursionConfigurationDisplayNamePipe,
    OptInGroupFilterBuilderFieldsPipe,
    ConferenceNameByDbIdPipe,
    HasPermissionPipe,
    MilitaryTimeFormatPipe,
  ],
})
export class PipesModule {}
