<div
  class="address-form__validation-container"
  *ngIf="formData?.[AddressModelKeys.validationResponse]?.result as result"
>
  <p *ngIf="!result?.verdict?.addressComplete">
    Address is Incomplete. Please verify that all required details are filled in.
  </p>
  <p *ngIf="result?.verdict?.hasUnconfirmedComponents">
    Address has Unconfirmed Data. If save as it is, it may not be deliverable or recognized accurately.
  </p>
</div>

<dx-validator [validationGroup]="validationGroup" [adapter]="googleAddressValidatorAdapter">
  <dxi-validation-rule
    type="async"
    [validationCallback]="googleValidationCallback"
    [message]="''"
    [reevaluate]="true"
  ></dxi-validation-rule>
</dx-validator>

<dx-form
  #addressFormRef
  class="address-form__form"
  [formData]="formData"
  labelMode="floating"
  [screenByWidth]="formSize$ | async | formScreenByWidth"
  (onInitialized)="onFormInitialized($event)"
  [validationGroup]="validationGroup"
  (onFieldDataChanged)="addressFormRef?.instance?.validate()"
>
  <dxi-item
    itemType="group"
    [colCountByScreen]="colCountByScreen"
    *ngIf="isTypeVisible || isPhysicalLocationVisible || isPrimaryShippingVisible || isPrimaryBillingVisible"
  >
    <dxi-item
      *ngIf="isTypeVisible"
      [dataField]="AddressModelKeys.addressType"
      [colSpan]="4"
      [label]="{ text: 'Address Type' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        readOnly: isReadonly,
        items: typesLookup,
        valueExpr: BaseModelKeys.dbId,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: '',
        onSelectionChanged: onSelectedAddressTypesChanged,
      }"
    ></dxi-item>

    <dxi-item itemType="group" [colSpan]="8" [colCountByScreen]="colCountByScreen">
      <dxi-item *ngIf="!isPhysicalLocationVisible" itemType="empty" [colSpan]="4"></dxi-item>
      <dxi-item *ngIf="!isPrimaryShippingVisible" itemType="empty" [colSpan]="4"></dxi-item>
      <dxi-item *ngIf="!isPrimaryBillingVisible" itemType="empty" [colSpan]="4"></dxi-item>

      <dxi-item
        *ngIf="isPhysicalLocationVisible"
        [dataField]="AddressModelKeys.isPhysicalLocation"
        [alignItemLabels]="true"
        [colSpan]="4"
        cssClass="ag-switch-with-label"
        [label]="{ text: 'Physical Location' }"
        editorType="dxSwitch"
        [disabled]="isReadonly"
        [editorOptions]="{ switchedOffText: 'No', switchedOnText: 'Yes' }"
      ></dxi-item>

      <dxi-item
        *ngIf="isPrimaryShippingVisible"
        [dataField]="AddressModelKeys.isPrimaryShipping"
        [alignItemLabels]="'left'"
        [colSpan]="4"
        editorType="dxSwitch"
        cssClass="ag-switch-with-label"
        [label]="{ text: 'Primary Shipping' }"
        [disabled]="isReadonly || isPrimaryShippingDisabled"
        [editorOptions]="{ switchedOffText: 'No', switchedOnText: 'Yes' }"
      ></dxi-item>

      <dxi-item
        *ngIf="isPrimaryBillingVisible"
        [dataField]="AddressModelKeys.isPrimaryBilling"
        [alignItemLabels]="'left'"
        [colSpan]="4"
        editorType="dxSwitch"
        cssClass="ag-switch-with-label"
        [label]="{ text: 'Primary Billing' }"
        [disabled]="isReadonly || isPrimaryBillingDisabled"
        [editorOptions]="{ switchedOffText: 'No', switchedOnText: 'Yes' }"
      ></dxi-item>
    </dxi-item>
  </dxi-item>

  <dxi-item
    [dataField]="AddressModelKeys.address1"
    [helpText]="
      AddressModelKeys.address1 | addressItemValidation: formData?.[AddressModelKeys.validationResponse]?.result
    "
  >
    <div *dxTemplate>
      <ag-shr-address-autocomplete
        [(value)]="formData[AddressModelKeys.address1]"
        (addressSelectionChange)="handleAddressSelectionChange($event)"
        [isReadonly]="isReadonly"
        [notValidated]="!formData?.[AddressModelKeys.placeId]"
        [validationGroup]="validationGroup"
      ></ag-shr-address-autocomplete>
    </div>
  </dxi-item>

  <dxi-item
    [dataField]="AddressModelKeys.address2"
    [label]="{ text: 'Address 2' }"
    [helpText]="
      AddressModelKeys.address2 | addressItemValidation: formData?.[AddressModelKeys.validationResponse]?.result
    "
    [isRequired]="isAddress2Required"
    [editorOptions]="{
      name: [pathPrefix, AddressModelKeys.address2] | path,
      readOnly: isReadonly,
      inputAttr: { autocomplete: 'disabled' },
    }"
  >
  </dxi-item>

  <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
    <dxi-item
      [dataField]="AddressModelKeys.city"
      [label]="{ text: 'City' }"
      [helpText]="
        AddressModelKeys.city | addressItemValidation: formData?.[AddressModelKeys.validationResponse]?.result
      "
      [colSpan]="6"
      [isRequired]="isCityRequired"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.city] | path,
        readOnly: isReadonly,
        inputAttr: { autocomplete: 'disabled' },
      }"
    ></dxi-item>

    <dxi-item
      [colSpan]="3"
      [helpText]="
        AddressModelKeys.state | addressItemValidation: formData?.[AddressModelKeys.validationResponse]?.result
      "
    >
      <div *dxTemplate>
        <ag-shr-state-select-box
          [name]="[pathPrefix, AddressModelKeys.state] | path"
          [value]="formData?.[AddressModelKeys.state]"
          (valueChange)="formData && (formData[AddressModelKeys.state] = $event)"
          [readOnly]="isReadonly"
          [validationGroup]="validationGroup"
          [isRequired]="isStateRequired"
          [showClearButton]="true"
        ></ag-shr-state-select-box>
      </div>
    </dxi-item>

    <dxi-item
      [dataField]="AddressModelKeys.zip"
      [label]="{ text: 'Zip Code' }"
      [helpText]="AddressModelKeys.zip | addressItemValidation: formData?.[AddressModelKeys.validationResponse]?.result"
      [colSpan]="3"
      [isRequired]="isZipRequired"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.zip] | path,
        readOnly: isReadonly,
        inputAttr: { autocomplete: 'disabled' },
      }"
    ></dxi-item>
  </dxi-item>

  <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
    <dxi-item
      *ngIf="isCountryVisible"
      [dataField]="AddressModelKeys.country"
      [label]="{ text: 'Country' }"
      [helpText]="
        AddressModelKeys.country | addressItemValidation: formData?.[AddressModelKeys.validationResponse]?.result
      "
      editorType="dxSelectBox"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.country] | path,
        acceptCustomValue: true,
        items: countries,
        valueExpr: 'name',
        displayExpr: 'name',
        searchEnabled: true,
        readOnly: isReadonly,
        placeholder: '',
        inputAttr: { autocomplete: 'disabled' },
      }"
      [isRequired]="isCountryRequired"
      [colSpan]="3"
    ></dxi-item>

    <dxi-item
      *ngIf="isCountyVisible"
      [dataField]="AddressModelKeys.county"
      [label]="{ text: 'County' }"
      [helpText]="
        AddressModelKeys.county | addressItemValidation: formData?.[AddressModelKeys.validationResponse]?.result
      "
      [colSpan]="3"
      [isRequired]="isCountyRequired"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.county] | path,
        readOnly: isReadonly,
        inputAttr: { autocomplete: 'disabled' },
      }"
    ></dxi-item>
  </dxi-item>
</dx-form>
