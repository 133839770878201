import { Pipe, PipeTransform } from '@angular/core';
import { ValidationResults } from '@ag-common-lib/lib';
import { AddressValidationStatus, checkAddressValidationStatus } from 'ag-common-svc/lib/utils/address.util';

@Pipe({ name: 'addressValidationButton' })
export class AddressValidationButtonPipe implements PipeTransform {
  constructor() {}

  transform(validationResults: ValidationResults, hasPermissionsToEdit, validateAddress): any {
    const addressValidationStatus = checkAddressValidationStatus(validationResults);
    const buttonWidget = {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'top',
      visible: true,
      options: {
        disabled: !hasPermissionsToEdit,
        type: undefined,
        icon: undefined,
        stylingMode: 'text',
        onClick: validateAddress,
        elementAttr: {},
      },
    };

    switch (addressValidationStatus) {
      case AddressValidationStatus.valid:
        buttonWidget.options.type = 'success';
        buttonWidget.options.icon = 'check';

        return [buttonWidget];
      case AddressValidationStatus.invalid:
        buttonWidget.options.type = 'danger';
        buttonWidget.options.icon = 'agi-ban';

        return [buttonWidget];

      case AddressValidationStatus.warning:
        buttonWidget.options.type = 'normal';
        buttonWidget.options.icon = 'warning';
        buttonWidget.options.elementAttr = {
          class: 'addresses-modal__validation-button--warning',
        };

        return [buttonWidget];

      default:
        return [];
    }
  }
}
