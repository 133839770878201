<ag-shr-modal-window
  #manageAddressModalRef
  [title]="addressFormTitle"
  [inProgress]="isValidationInProgress || inProgress"
  (onSaveClick)="handleAddressSave($event)"
  [onCloseModal]="handleClosePopup"
  [showSaveButton]="hasPermissionsToAdd || hasPermissionsToEdit"
  [height]="'auto'"
  [extraToolbarItems]="
    addressToEdit?.[AddressModelKeys.validationResponse]?.result
      | addressValidationButton: hasPermissionsToAdd || hasPermissionsToEdit : validateAddress
  "
>
  <ng-container *ngIf="manageAddressModalRef?.popupComponent?.visible ?? false">
    <dx-validation-summary
      class="addresses__validation-summary"
      [validationGroup]="addressUniqValidationGroup"
    ></dx-validation-summary>

    <dx-validator [validationGroup]="addressUniqValidationGroup" [adapter]="addressUniqAdapter">
      <dxi-validation-rule
        type="async"
        [validationCallback]="checkIsAddressUniq"
        [message]="'Same address already exists in this profile'"
        [reevaluate]="true"
      ></dxi-validation-rule>
    </dx-validator>

    <ag-shr-address-form
      #addressFormRef
      [isPrimaryBillingVisible]="showBillingAddress"
      [isPhysicalLocationVisible]="showPhysicalLocation"
      [isPrimaryShippingVisible]="showShippingAddress"
      [address]="addressToEdit"
      [typesLookup]="typesLookup"
      [validationGroup]="addressValidationGroup"
      [isReadonly]="!hasPermissionsToAdd && !hasPermissionsToEdit"
    ></ag-shr-address-form>
  </ng-container>
</ag-shr-modal-window>
